<!--:totalRecords="totalRecords"
:lazy="true"
--><!--:lazy="true"-->
<!--@filter="onFilter($event)"-->
<!--:filters="filters"-->
<!--@filter="onFilter($event)"-->
<!--stateStorage="session"
    stateKey="dt-state-demo-session"-->
    <template>
  <DataTable
    :key="key_table"
    :value="listDataService"
    :lazy="true"
    class=""
    selectionMode="single"
    data-key="id"
    @page="onPage($event)"
    :paginator="pagination"
    :totalRecords="totalRecords"
    :rows="paginationRows"
    :loading="loading"
    :scrollable="(scrollHeight !== '' ? true : false) "
    :scrollHeight="scrollHeight"
    :autoLayout="true"
    filterMatchMode="startsWith"
    :first="firstRecordIndex"
    :rowsPerPageOptions="$constants.LIST_OPTION_COMMON.PER_PAGE_OPTIONS"
    :currentPageReportTemplate=" $constants.LIST_OPTION_COMMON.TEXT_TOTAL_ITEM+' {totalRecords}'"
    :paginatorTemplate="$constants.LIST_OPTION_COMMON.PAGINATOR_TEMPLATE"
  >


<!--    <div class="div_search">
      <div class="div_date div_search_mobi div_pc">
        <label class="p-col-fixed txt-right text_align label_company">Xem thống kê </label>
        <Calendar class=" margin input_width border_color Calendar width_mobi" dateFormat="dd-mm-yy" placeholder="DD-MM-yyyy"  id="date" v-model="date" :showIcon="true" />
      </div>

      <div class="div_company div_search_mobi div_pc">
        <label class="p-col-fixed txt-right text_align label_company ">Công ty</label>
        <Dropdown id="selectType" @change="dataList" class="input_text width_mobi " v-model="SelectedCompany" :options="company" optionLabel="name" optionValue="id" placeholder="Chọn" />
      </div>
      <div class="button_import">
        <Button v-on:click="show" label="Xuất excel" class="p-button-success" />
      </div>
    </div>-->




    <Dialog
        :header="header"
        :visible.sync="showPagekageDialog"
        :contentStyle="{overflow: 'visible'}"
        :modal="true"
    >
      <div class="div_label">
        <div class="left-parcel">
          <label class="p-col-fixed txt-right  label_company ">Kiện hàng</label>
        </div>
        <div class="right-parcel">
          <label class="p-col-fixed txt-right  label_company label_quantity">Số lượng</label>
        </div>
      </div>
      <div class="parcel style_input_parcel" v-for="c in list_package" :key="c.id">
        <div class="left-parcel">
      <div class="p-card-field margin_botton" >
<!--        <div class="p-col">-->
          <InputText disabled  class="p-col-width  border_color width_input_popup" maxlength="255" v-model="c.name"   type="text"  placeholder="Nhập " />
<!--        </div>-->
      </div>
      </div>
        <div class="right-parcel">
      <div class="p-card-field margin_botton">
<!--        <div class="p-col">-->
          <InputText disabled class="p-col-width  border_color input_quantity" maxlength="255" v-model="c.number"  id="name" type="text"  placeholder="Nhập " />
<!--        </div>-->
      </div>
      </div>
      </div>


<!--      <div class="quantity">
     <div class="p-card-field margin_botton">
        <div class="p-col">
          <InputText disabled class="p-col-width  border_color width_input_popup" maxlength="255" v-model="parcel.top"  id="name" type="text"  placeholder="Nhập " />
        </div>
      </div>
      <div class="p-card-field margin_botton"  v-for="d in list_package" :key="d.id">
        <div class="p-col">
          <InputText class="p-col-width  border_color input_quantity " maxlength="255" v-model="d.number"  id="name" type="text"  placeholder="Nhập " />
        </div>
      </div>
      </div>-->
      <strong></strong>
      <template
          #footer
      >


        <Button @click="showPagekageDialog = false" label="Đóng" icon="pi pi-check" class="main-style-button" />
      </template>
    </Dialog>


    <Column
      v-for="c in model.getDisplayFields()"
      :key="c.key"
      :field="getField(c)"
      :header="getLabel(c)"
      :ref="getField(c)"
      :filterMatchMode="getFilterMatch(c)"
      :sortable="getSortable(c)"
      :bodyClass="(c.class ? 'txt-' + c.class : '')"
      :bodyStyle="getHeaderStyle(c)"
      :headerStyle="getHeaderStyle(c)"
      :headerClass="(c.class ? 'txt-' + c.class : '')"
    >
      <template #body="slotProps" >
        <!--{{c}}-->
        <img
          v-if="getType(c) == 'Image'"
          :src="getData(c, slotProps.data)"
          style="width: 40px; heigth: 40px"
        />
        <div
          v-else-if="c.type == 'Text'"
          v-html="getData(c, slotProps.data)"
          :style="(c.style ? c.style : '')"
        ></div>
        <div
          v-else-if="getType(c) == 'Custom' || getType(c) == 'Icon'"
          v-html="getData(c, slotProps.data)"
          :style="(c.style ? c.style : '')"
        ></div>

        <Checkbox
          v-else-if="getType(c) == 'Checkbox'"
          v-model="slotProps.data[slotProps.column.field]"
          :binary="true"
          @change="onInputChange($event, slotProps.data, c)"
        />
        <div v-else>

          <div v-if="c.custom">
            <Avatar v-if="c.custom == 'image'" :image="getData(c, slotProps.data)" size="xlarge"  />
<!--            <div v-if="c.custom == 'CheckBoxSelect'" >-->
<!--              <Checkbox :disabled="slotProps.data.is_lock == true ? true:false"  v-model="list_select_item" :value="slotProps.data.code" @change="process_change_checkbox_item()" />-->
<!--            </div>-->
<!--            -->
            <div v-if="c.custom == 'CheckBoxSelect'"  >
              <img v-if="list_order_code.indexOf(slotProps.data.code) != -1"  @click="remove_click(slotProps.data)" src="/assets/images/icon/icon_check_active.svg">
              <img v-else  @click="click_icon(slotProps.data)" src="/assets/images/icon/ic_check.svg">
            </div>
            <div v-if="c.custom == 'SelectionText'"  v-html="callbackDataSelect( c ,slotProps.data)"></div>
            <div @click.capture="click_tag_a(slotProps.data)" class="click_tag_a" v-if="c.custom == 'CutomCLickA'"  >{{slotProps.data.delivery_trip_number}}</div>
            <div @click.capture="showPackage(slotProps.data)" class="click_tag_a" v-if="c.custom == 'CutomCLickB'"  >{{slotProps.data.total_package}}</div>
            <img
                v-if="c.custom == 'icon' && slotProps.data.is_lock == false"
                src="/assets/images/icon/ic_khoaso_unactive.svg"
                style="width: 40px; heigth: 40px"
            />
            <img
                v-if="c.custom == 'icon' && slotProps.data.is_lock == true"
                src="/assets/images/icon/ic_khoaso_active1.svg"
                style="width: 40px; heigth: 40px"
            />
          </div>
          <div v-else :class="getCustomClass(c, slotProps.data[c.key], slotProps.data)">
            <span  v-html="getData(c, slotProps.data)" ></span>
          </div>

        </div>

      </template>
      <template v-if="c.filter" #filter>
        <div v-if="c.custom">
          <div v-if="c.custom == 'CheckBoxSelect'">
            <img  v-if="checkBoxAll == true" @click="click_icon_all(false)" src="/assets/images/icon/icon_check_active.svg">
            <img v-else  @click="click_icon_all(true)" src="/assets/images/icon/ic_check.svg">
          </div>
<!--        <Checkbox v-if="c.custom == 'CheckBoxSelect'"  v-model="checkBoxAll" :binary="true" @change="process_change_checkbox_all($event)" />-->
        </div>
        <InputText
          v-if="c.filter.type == 'input'"
          type="text"
          v-model="filters[$CommonList.getFieldFilter(c)]"
          class="p-column-filter"
          :placeholder="getFilterPlaceHolder(c)"
          @keyup="onFilter($event,c.filter.type,c.key)"
        /><!-- :keyup="onFilter(c.filter.type,c.key)"-->
        <!--v-model="filters[getField(c)]"-->
        <Dropdown
          :key="countDropdown"
          v-if="c.filter.type == 'select'"
          v-model="filters[getField(c)]"
          :options="getFilterOptions(c.key)"
          :optionLabel="c.filter.optionLabel"
          :optionValue="c.filter.optionKey"
          :placeholder="getFilterPlaceHolder(c)"
          class="p-column-filter"
          :showClear="true"
          appendTo="body"
          @change="onFilter($event,c.filter.type,c.key)"
        >
          <template #option="slotProps">
            <div class="p-clearfix">
              <span
                :class="getCustomClass(c, slotProps.option[c.filter.optionKey], slotProps.data)"
                >{{ slotProps.option[c.filter.optionLabel] }}</span
              >
            </div>
          </template>
        </Dropdown>
<!--        <Calendar
          v-if="c.filter.type == 'DateRange'"
          :showOnFocus="false"
          :monthNavigator="true"
          :yearNavigator="true"
          yearRange="2000:2030"
          dateFormat="yy-mm-dd"
          id="date_from"
          v-model="filters[getField(c)]"
          class="p-column-filter"
          :showIcon="true"
          selectionMode="range"
          appendTo="body"
          :showClear="true"
          @date-select="select_date($event,getField(c))"
          :manualInput="false"
          />-->
        <Calendar
            :key="countCalendar"
            v-if="c.filter.type == 'DateRange'"
            :showOnFocus="true"
            :monthNavigator="true"
            :yearNavigator="true"
            :yearRange="yearRange"
            dateFormat="yy-mm-dd"
            :placeholder="getFilterPlaceHolder(c)"
            v-model="filters[getField(c)]"
            class="p-column-filter calendar-light-btn"
            :showIcon="true"
            selectionMode="range"
            appendTo="body"
            :showClear="true"
            :showButtonBar="true"
            @clear-click="onFilter($event,c.filter.type,c.key)"
            @date-select="onFilter($event,c.filter.type,c.key)"
            :manualInput="false"
            style="width: 200px;"
        />
      </template>
    </Column>
    <Column
      v-if="actions"
      headerStyle="width: 10em; text-align: center"
      bodyStyle="width: 10em; text-align: center"
    >
      <template #body="slotProps">
        <!-- <span class="p-column-title">Actions</span> -->
        {{ slotProps.data.color }}
        <Button
          v-for="(a, k) in actions.rows(slotProps.data)"
          :key="k"
          type="button"
          :icon="getIcon(a.icon)"
          :class="getClass(a.name)"
          :disabled="getDisabled(a.disabled)"
          style="margin: 2px"
          v-on:click="actionsClicked(k, a, slotProps)"
        ></Button>
      </template>
      <template #header="slotProps">
        <Button
          v-if="actions.header"
          type="button"
          :icon="getIcon(actions.header.icon)"
          :class="getClass(actions.header.name)"
          v-on:click="actionsClicked(actions.header.name, actions.header, slotProps)"
        ></Button>
      </template>
    </Column>
    <template #empty>
      {{ $t('datatable.empty') }}
    </template>
    <template #loading>
      {{ $t('datatable.loading') }}
    </template>
  </DataTable>
</template>

<script>
import DataServices from '@/core/DataServices'
import moment from "moment-timezone";
//import gql from "graphql-tag";
//import DataServices from "@/core/DataServices";
import ApiRepository from "@/core/ApiRepository";
export default {
  props: {
    model: Object,
    tableData: Array,
    getFilterOptions: Function,
    actions: Object,
    options: Array,
    whereKeyList: Object,
    filtersDefault: Object,
    is_load_fillter: Number,
  },
  data() {
    return {
      header:"",
      yearRange : (new Date().getFullYear() - 100)+":"+(new Date().getFullYear() + 100),
      order_group_parcel_id_tmp:null,
      //filters: {},
      parcel:{
        top:'',
        bot:''
      },
      quantity:{
        top:'',
        bot:''
      },
      SelectedCompany:null,
      company:[],
      list_order_code:[],
      import_excel:{
        date:null,
        dear:'',
      },
      showDeleteDialogExcel:false,
      list_map_code_order: {},
      showPagekageDialog: false,
      countDropdown: 1,
      countCalendar: 1,
      pagination: true, // true cho phép phân trang
      paginationRows: this.$constants.LIST_OPTION_COMMON.PAGINATION_ROWS_DEFAULT, // số dòng trên 1 trang
      scrollHeight: '', // chiều cao của trang danh sách
      loading: true,
      rowsPerPageOptions: [10, 20, 50, 100],
      first: 0,
      totalRecords: 120,
      totalRecords2: 12,
      test: null,
      listDataService:null,
      filters: {},
      filtersCustom: [],
      lazyParams: {},
      page_transaction:null,
      firstRecordIndex:0,
      list_select_item:[],
      checkBoxAll:false,
      delivery_personnel_map:{},
      vehicle_delivery_map:{},
      key_table:0,
      list_package:[],
      code_root_package:''
    };
  },
  async created() {
    console.log("filtersDefaultfiltersDefault:",this.filtersDefault)

    var gqLQueryList = DataServices.getList('company');
    console.log("gqL",gqLQueryList)
    var resData = await this.$apollo.mutate({
      mutation: gqLQueryList,
      variables: {
        orderBy: {created_at: 'desc'}
      }
    });
    var listData = resData.data['company'];
    this.company = listData;



    console.log("modelmodelmodelmodelmodelmodelmodelmodelmodel:",this.model)
    console.log(this.tableData);
    this.test = this.tableData;
    //this.listDataService = await this.dataList();
    console.log("this.optionsthis.options:",this.options)
    if (!this.$commonFuction.isEmpty(this.options)){
      if (!this.$commonFuction.isEmpty(this.options.delivery_personnel)){
          var delivery_personnel = this.options.delivery_personnel
          this.delivery_personnel_map =  this.map_option(delivery_personnel);
      }
      if (!this.$commonFuction.isEmpty(this.options.vehicle_delivery)){
        var vehicle_delivery = this.options.vehicle_delivery
        this.vehicle_delivery_map =  this.map_option(vehicle_delivery);
      }
    }
  },
  mounted () {
    console.log("this.filtersDefault:",this.filtersDefault)
    if (!this.$commonFuction.isEmpty(this.filtersDefault['company_id'])){
      this.filters["company_id"] = this.filtersDefault['company_id']
    }
    if (!this.$commonFuction.isEmpty(this.filtersDefault['date_order1'])){
      this.filters["date_order1"] = this.filtersDefault['date_order1']
    }
    console.log("this.filters:",this.filters)
    this.lazyParams = {
      first: 0,
      rows: this.paginationRows,
      sortField: null,
      sortOrder: null,
      filters: this.$commonFuction.convertJsonObject(this.filters),
    //  filtersTmp:this.filters
    };
    console.log(" this.lazyParams:sssss", this.lazyParams);
    if (this.is_load_fillter == 1) {
      if (!this.$commonFuction.isEmpty(this.$route.query)) {
        if (!this.$commonFuction.isEmpty(this.$route.query.page_transaction)) {
          this.page_transaction = this.$route.query.page_transaction;
          this.lazyParams = (localStorage.getItem(this.page_transaction)) ? JSON.parse(localStorage.getItem(this.page_transaction)) : {};
          console.log("this.lazyParamsthis.lazyParams:" + this.page_transaction, this.lazyParams);
          this.firstRecordIndex = this.lazyParams.first;
          console.log("this.lazyParams:22222222", this.lazyParams);
          console.log("this.is_load_fillter:", this.is_load_fillter)
          // if (this.is_load_fillter == 1){
          this.callbackFilter()
          /* }else {
          console.log("this.adasdas:")
          this.$delete(this.lazyParams, 'list_filter');
          //this.lazyParams.list_filter =[]
        }*/

          window.history.pushState({"html": "", "pageTitle": ""}, "", '/admin/list-report_expense');
          // window.history.pushState({"html":"","pageTitle":""},"", '/admin/list-order');
          /* if (this.lazyParams){
           console.log("this.lazyParams.filters",this.lazyParams.filters);

         }*/
        }
      }
    }else {
     // this.lazyParams.list_filter =[]
    }


    this.loading = false
    this.countDropdown++;
    this.countCalendar++;
    this.onLazyEvent();
  },
  /*watch: {
    async '$props.filtersDefault.company_id'() {
      this.lazyParams.filters = await Object.assign({...this.filters, ...this.filtersDefault});
      this.onLazyEvent();
    },
    async '$props.filtersDefault.date_order'() {
      this.lazyParams.filters = await Object.assign({...this.filters, ...this.filtersDefault});
      console.log("this.lazyParams.filtersthis.lazyParams.filtersthis.lazyParams.filters", this.lazyParams.filters);
      this.onLazyEvent();
    },
  },*/
  methods: {

    callbackFilter(){
      var list_filter =  this.lazyParams.list_filter
      console.log("list_filterlist_filter:",list_filter)
      //var obj_filter = {};
      for (let i = 0; i < list_filter.length; i++) {
        var fil = list_filter[i]
        for (let key in fil) {
          var item_data = fil[key]
          this.filtersCustom[key] = fil[key]
          if (item_data.type_input == 'DateRange'){
            var array_data_tmp = [new Date(item_data?.value?.from),new Date(item_data?.value?.to)];
            this.$set(this.filters, key,array_data_tmp );
          }else {
            this.$set(this.filters, key,fil[key].value );
          }
        }
      }
    },

    click_icon(data){
      if (data.is_lock == true){
        return false
      }
      this.list_order_code.push(data.code)
      console.log("list_order_code",this.list_order_code)
      this.process_emit_check_box()
    },

    remove_click(data){
      if (data.is_lock == true){
        return false
      }
      var list_code = []
      for (var i = 0 ; i < this.list_order_code.length ; i++){
        if (data.code != this.list_order_code[i]){
          list_code.push(this.list_order_code[i])
        }
      }
      this.list_order_code = list_code
      this.process_emit_check_box()
    },

    click_icon_all(is_active){
      this.checkBoxAll = is_active
      var list_code = []
      if (this.checkBoxAll == true){

        for (var i = 0 ; i< this.listDataService.length ; i++){
          if (!this.listDataService[i].is_lock){
            list_code.push(this.listDataService[i].code)
            console.log("saiwqwqw",this.listDataService[i].code)
          }
        }
        console.log("list_order_code",list_code)
        this.list_order_code = list_code

      }
      else {
        console.log("saksaksa")
        this.list_order_code = []
      }
      this.process_emit_check_box()
    },




    async process_click_status(type_status,title_page='',button_name='') {
      console.log("type_status:",title_page)
      console.log("type_status:",button_name)
      if (type_status == 'LOCK_UP'){
        this.display_lock_up = true
      }else if (type_status == "EXPORT_EXCEL"){
        console.log("type_status:",type_status)
        this.process_export_excel();
      }
    },
    async showPackage(data){
      for (var i = 0 ; i < this.listDataService.length; i++){
        if (this.listDataService[i].order_group_parcel_id == data.order_group_parcel_id){
          console.log("header",this.listDataService[i].code)
          this.header = this.listDataService[i].code

        }
      }
      console.log('this.listDataService11',this.listDataService)
      console.log('data.order_group_parcel_id',data.order_group_parcel_id)
      this.showPagekageDialog = true
      this.order_group_parcel_id_tmp = data.order_group_parcel_id
      var list_package =  await this.load_pakage()
      this.list_package = list_package;
      //console.log("list_packagelist_package:",list_package)
    },
    async load_pakage() {
      var order_group_parcel_id = this.order_group_parcel_id_tmp
      //this.obj_id
      console.log("order_group_parcel_id:",order_group_parcel_id)
      if (this.$commonFuction.isEmpty(order_group_parcel_id)) {
        this.list_pakage = [];
        return false;
      }
      var gqLQueryListOrderDocs = DataServices.getList('order_parcel');
      var where_user_docs = {
        order_group_parcel_id: {_eq: order_group_parcel_id},
        deleted: {_eq: false},
      }
      var resData_docs = await this.$apollo.mutate({
        mutation: gqLQueryListOrderDocs,//
        variables: {
          where_key:where_user_docs ,
          /*offset:offset,
          limit:limit,*/
          orderBy:{created_at: 'desc'}
        }
      });
      var listData = resData_docs.data['order_parcel'];
      var list_convert = [];
      for (var i=0;i<listData.length;i++){
        var item_data = listData[i];
        item_data["name"] = (item_data["parcel"]) ? item_data["parcel"]["name"]:''
        list_convert.push(item_data)
      }
      return list_convert;
    },
    click_tag_a(data){
      var pageTransaction = (new Date()).getTime();

      var filter = this.lazyParams.filters
      var filter_root = this.filters ?? []
      console.log("filter_rootfilter_root11:",filter_root)
      var list_filter = []
      for (let key in filter) {
        //if (filter.hasOwnProperty(key)) { // Ensure the property belongs to the object itself, not inherited
        console.log(`wwwwwww ${key}: ${filter[key]}`);
        //}
        var itemdata={};
        itemdata[key] = filter[key];
        list_filter.push(itemdata)
      }
      this.lazyParams.list_filter = list_filter;
      this.lazyParams.filter_root = filter_root;
      console.log("payload.filterfilter",filter);
      console.log("payload.lazxsdsss",JSON.stringify(this.lazyParams));
      localStorage.setItem(pageTransaction.toString(), JSON.stringify(this.lazyParams));


      this.$router.push({ path: '/admin/list-report_expense/edit/'+data.id ,query: { page_transaction: pageTransaction } })
    },
    map_option(list_data){
      var list_map = {}
      for (var i=0;i<list_data.length;i++){
       // console.log("list_data[i]list_data[i]:",list_data[i])
        var value = list_data[i]["value"]
        //console.log("valuevaluevalue:",value)
        var label = list_data[i]["label"]
        list_map[value]=label
      }
      //console.log("list_maplist_map",list_map)
      return list_map;
    },
    process_change_checkbox_item(){
      this.process_emit_check_box()
      return 1;
    },
    process_change_checkbox_all(event){
      console.log("444444444444444")
      //console.log("process_change_checkbox_all:",this.checkBoxAll)
      if (event);
      if (this.checkBoxAll == true){
        console.log("listDataService",this.listDataService)
        var list_code_order = [];
        for (var i=0;i<this.listDataService.length;i++){
          var code = this.listDataService[i]['code']
          var is_lock = this.listDataService[i]['is_lock']
          if (is_lock == false){
            list_code_order.push(code);
          }
        }
        this.list_select_item = list_code_order;

      }else {
        this.list_select_item = [];
      }
      this.process_emit_check_box()
    },
    process_emit_check_box(){
      var obj = {
        list_select_item:this.list_order_code,
        list_map_code_order:this.list_map_code_order,
      }
      this.$emit("action-list_select_item", obj);
    },
    callbackDataSelect(c,data){
      console.log("delivery_personnel_map:",this.delivery_personnel_map)
      console.log("vehicle_delivery_map:",this.vehicle_delivery_map)
      var text = "";
      switch(c.key) {
        case 'total_number_request':
          var total_number_request =  (!this.$commonFuction.isEmpty(data.total_number_request)) ? data.total_number_request:0;
          var total_number_process =  (!this.$commonFuction.isEmpty(data.total_number_process)) ? data.total_number_process:0;
          if (total_number_request>0 ){
            text = total_number_process+"/"+total_number_request;
          }else {
            text = "-";
          }
          break;
        case 'reasons_failure_key':
          text =  (!this.$commonFuction.isEmpty(data.reasons_failure)) ? data.reasons_failure:'';
          break;
        case 'current_order_status_id':
           text =  (!this.$commonFuction.isEmpty(data.order_status)) ? data.order_status.name:'';
          break;
        case 'total_deliveries':
          text =  (!this.$commonFuction.isEmpty(data.total_deliveries)) ? data.total_deliveries:'';
          break;
        case 'delivery_personnel':
          if (data.order_ship_item){
            if (data.order_ship_item.order_shipping_key){
              var uid = data.order_ship_item.order_shipping_key.uid;
              text = this.delivery_personnel_map[uid]
            }
          }
          break;
        case 'vehicle_delivery':
          if (data.order_ship_item){
            if (data.order_ship_item.order_shipping_key){
              var vehicle_id = data.order_ship_item.order_shipping_key.vehicle_id;
              text = this.vehicle_delivery_map[vehicle_id]
            }
          }
          break;
        default:
      }
      return text;
    },
    select_date(event,col_name) {
      if (this.filters[col_name][0] !== null && this.filters[col_name][1] !== null) {
        this.$emit("filter_date", {
          data: this.filters[col_name],
          col: col_name
        });
      }
    },
    getLabel(col) {
      if (col.label) return col.label;
      else return col.name;
    },
    getType(col) {
      if (col.display?.list?.mode == "custom") {
        return "Custom";
      }
      switch (col.type) {
        case "String":
        case "Text":
        case "Computed":
          if (col.inputOptions?.mode == "Image") {
            return "Image";
          }
          if (col.inputOptions?.mode == "selection") {
            return "Select";
          }
          if (col.inputOptions?.mode == "currency") {
            return "Currency";
          }
          if(col.inputOptions?.mode == 'icon') {
            return "Icon";
          }
          return "Input";
        case "numeric":
        case "ShadowField":
        case "number":
          if (col.inputOptions?.mode == "currency") {
            return "Currency";
          } else if (col.inputOptions?.mode == "percentage") {
            return "Percentage";
          } else {
            return "Number";
          }
        case "Selection":
          return "Select";
        case "Checkbox":
          return "Checkbox";
        default:
          return col.type;
      }
    },
    cannotEdit(col) {
      if (col.edit && col.edit.edit == false) return true;
      return false;
    },
    onInputChange(value, data, f) {
      if (f.onChange)  f.onChange(data).bind(this);
      this.$emit("onDataChange", data, f);
    },
    toggle(event) {
      this.$refs.op.toggle(event);
    },
    getCustomClass(col, data, rowData) {
      if (col.displayClass) return col.displayClass(data, rowData);
      return "";
    },
    getField(col) {
      return col.key;
    },
    getHeaderStyle(col) {
      var style = "";
      if (col.width) style = "width: " + col.width + "em;";
      else if (col.name === "stt" || col.name === "STT") style = "width: 3em;";
      else if (col.type === "image" || col.type === "Image") style = "width: 6em;";
      return style;
    },
    getData(col, row) {
      //console.log("colcolcol:",col);
      //console.log("rowrowrowrow:",row);
      var value = '';
      if (col.type == "Date" && row[col.key]) {
        value = moment.tz(row[col.key], "Asia/Ho_Chi_Minh").format("DD/MM/yyyy");
      } else if (col.type == "Datetime" && row[col.key]) {
        value = moment.tz(row[col.key], "Asia/Ho_Chi_Minh").format("DD/MM/yyyy HH:mm:ss");
      } else if (col.type == "CustomDate" && row[col.key]) {
        value = moment.tz(row[col.key], "Asia/Ho_Chi_Minh").format("HH:mm - DD/MM/yyyy");
      } else if(col.type == "Hour" && row[col.key]){
        value = moment.tz(row[col.key], "Asia/Ho_Chi_Minh").format("HH:mm");
      } else if (col.type == "Selection") {
        var lists = [];
        if (col.options !== undefined) {
          lists = col.options;
        } else if (col.models !== undefined && this.options[col.key] !== undefined) {
          lists = this.options[col.key];
        }
        if (lists && lists.length) {
          var objResult = lists.filter(a => a.value == row[col.key]);
          if (objResult !== undefined && Object.keys(objResult) && Object.entries(objResult).length) {
            value = objResult[Object.keys(objResult)].label;
          }
        }
      } else {
        if (col.type == "Text" && row[col.key] && row[col.key].length > 100) {
          var result = row[col.key].substr(0, row[col.key].lastIndexOf(" ", 100)) + "...";
          value = result;
        }
        if (this.getType(col) == "Currency") {
          if (row[col.key] == null) row[col.key] = 0;
          value = this.formatCurrency(row[col.key]);
        }
        if (this.getType(col) == "Percentage") {
          if (row[col.key] == null) row[col.key] = `0%`;
          value = row[col.key] + "%";
        }
        value = row[col.key];
      }
      if (col.style) value = '<abbr style="' + col.style + '">' + value + '<abbr/>';
      else if (col.options) {
        var filtered = col.options.filter( a => a.value  === row[col.key]);
        if (Object.keys(filtered) && Object.entries(filtered).length && filtered[0].style) {
          value = '<abbr style="' + filtered[0].style + '">' + value + '<abbr/>';
        }
      } 
      return value
    },
    getFilterMatch(col) {
      var filterMatch = "startWith";
      if (col.filter && col.filter.match) {
        filterMatch = col.filter.match;
      }
      return filterMatch;
    },
    getFilterPlaceHolder(col) {
      return this.$CoreService.getFilterCommonPlaceHolder(col);
    },
    getSortable(col) {
      return col.sortable && col.sortable == true ? true : false;
    },
    getIcon(iconName) {
      return `pi pi-${iconName}`;
    },
    getDisabled(disabled = false) {
      return disabled;
    },
    actionsClicked(key, action, rowData) {
      this.$emit("action-click", {
        originalEvent: event,
        action: action,
        key: key,
        data: rowData,
        lazyParams:this.lazyParams
      });
    },
    getClass(type) {
      switch (type) {
        case "add":
          return "p-button-add";
        case "delete":
          return "p-button-danger";
        case "edit":
          return "p-button-edit";
        case "adddebt":
          return "p-button-edit"
        case "add-Debt":
          return "p-button-add";
        case "editcustom":
          return "p-button-edit"
        case "addcustom":
          return "p-button-add";
      }
    },
    formatCurrency(value) {
      return value.toLocaleString("vi-VN", {
        style: "currency",
        currency: "VND"
      });
    },
   async dataList(lazyParams) {
      console.log('lazyParams');
      console.log('lazyParams:',lazyParams);
     var offset = lazyParams.first;
     var limit = lazyParams.rows;
     console.log("this.model.tableName:",this.model.tableName)
    // var gqLQueryList = DataServices.getList(this.model.tableName);
     var where = {};
     var filters = lazyParams.filters;
     console.log("filtersfilters11:",filters)
     var dataRequest = {
       //  filters: [],
       offset:offset,
       limit:limit,
     };
     if (filters){
       console.log("filtersfilters:",filters)
       for (const [key, value] of Object.entries(filters)) {
         dataRequest[key] = value;
         if (!this.$commonFuction.isEmpty(value.value)) {
           if (value.type_input == 'input') {
             if (key == 'fullname'){
               where['plain_fullname'] = {_like: "%"+this.$commonFuction.SkipVN(value.value)+"%"};
             }else {
               where[key] = {_like: "%" + value.value + "%"};
             }
           } else if (value.type_input == 'select') {
             if (key == 'name_group_filter') {
               where['group_id'] = {_eq: value.value};
             }
           }
         }
       }
     }/*
     var resData = await this.$apollo.mutate({
       mutation: gqLQueryList,
       variables: {
         where_key:where ,
         offset:offset,
         limit:limit,
         orderBy:{created_at: 'desc'}
       }
     });
     var listData = resData.data[this.model.tableName];*/
     var listData = []

     /*var gqLQueryCount = DataServices.countData(this.model.tableName);
     var countRes = await this.$apollo.mutate({
       mutation: gqLQueryCount,
       variables: {
         where_key:where
       }
     });
     var total = countRes.data[this.model.tableName+'_aggregate']['aggregate']['count'];*/
     var total = 0;
//console.log("this.$commonFuction.convertJsonObject(filters):",this.$commonFuction.convertJsonObject(filters))
    // console.log("filtersfilters:",filters)

     const headers = {
        "Authorization": "Bearer "+(await this.$AuthenticationApp.getToken()),
     };
     this.$emit("emit_filter", dataRequest);
     var list_action_detail = await ApiRepository.post('/_api/order/api-detail-cost-service', dataRequest, {headers});
     listData = list_action_detail["data"]["data"]["list_data"]
     total = list_action_detail["data"]["data"]["total"]
     var dataRes = {
       listData: listData,
       total: total,
     };
     console.log('dataRes:',dataRes);
     return dataRes;
    },
    onLazyEvent() {
      this.lazyParams.filters = {...this.lazyParams.filters, ...this.filtersCustom};
      console.log('this.lazyParams:', this.lazyParams)
      this.loading = true;
      var that = this;
      this.dataList(this.lazyParams).then(async data => {
        console.log("datadata:", data)
        //var count = 0;
        if (data.listData.length < 1) {
          that.listDataService = data.listData;
          that.totalRecords = data.total;
          that.loading = false;
        }
        var stt = this.lazyParams.first;
        //var list_id = []
        var list_map_code_order = {}
        for (var i = 0; i < data.listData.length; i++) {
          stt++;
          data.listData[i].stt = stt;
          list_map_code_order[data.listData[i].code] = data.listData[i]
          //list_id.push( data.listData[i].id)
        }
        this.list_map_code_order = list_map_code_order
        /*
        var dataRequest = {
          list_id: list_id,
        };
        const headers = {
          "Authorization": "Bearer "+(await this.$AuthenticationApp.getToken()),
        };
        var item_order_ship = await ApiRepository.post('/_api/order/get-order-ship', dataRequest, {headers});
        var order_ship_map  = (item_order_ship["data"]["data"]) ? item_order_ship["data"]["data"]:null
        console.log("order_ship_map:",order_ship_map)
       for (var j = 0; j < data.listData.length; j++) {
          var order_id = data.listData[j].id
          if (order_ship_map){
            var order_ship_item = order_ship_map[order_id]
            if (!this.$commonFuction.isEmpty(order_ship_item)){
              data.listData[j].order_ship_item = order_ship_item
              console.log("order_ship_item.freight_key:",order_ship_item.freight_key)
              if (!this.$commonFuction.isEmpty(order_ship_item.freight_key)){
                data.listData[j].address_delivery1 = order_ship_item.freight_key.address
               // data.listData[j].address_delivery1 = 'asdasdasd'
              }
            }

          }
        }*/
        that.listDataService = data.listData;
        that.totalRecords = data.total;
        that.loading = false;
        that.model.processData(data.listData)
        that.countDropdown++;
        that.countCalendar++;
        //that.key_table++
      });
    },
    onPage(event) {
      console.log("eventeventeventevent",event);
      this.lazyParams = event;
      this.onLazyEvent();
    },
    async onFilter(event,type_input,key) {
      //this.loading = true;
      this.tableData = this.test;
      console.log('this.test',this.test);
      console.log('this.test',this.tableData);
      console.log('tableData',this.whereKeyList);
      console.log('filters:',this.filters);
      /*this.filtersCustom[key] = {
        type_input:type_input,
        value:this.filters[key],
      };*/
      if(type_input == "DateRange"){
        if(this.filters[key] !== null && this.filters[key][1]== null){
          return false;
        }
      }
      this.filtersCustom[key] = await this.$CoreService.convertFilter(type_input,key,this.filters);
      console.log('this.filtersCustom:',this.filtersCustom);
      this.lazyParams.first = 0;
      this.onLazyEvent()
      /*setTimeout(() => {
        this.customers = this.datasource.slice(event.first, event.first + event.rows);
        this.loading = false;
      }, 500);*/
    },
  }
};
</script>
<style lang="scss">
.p-paginator-bottom{padding: 0 !important;}
.click_tag_a{
  text-align: center;
  text-decoration: underline;
  font: normal normal normal 16px/21px Segoe UI;
  letter-spacing: 0px;
  color: #C40380;
  cursor: pointer;
}
div.p-dialog{
  width: 600px!important;
}
.parcel{
  display: flex!important;
}
.quantity{
  display: flex!important;
}
.width_input_popup{
  width: 332px!important;
}
.input_quantity{
  width: 100%!important;
}
.div_label{
  display: flex!important;
  /*justify-content: space-between!important;*/
  font-weight: bold!important;
}
.label_quantity{
  margin-right: 143px!important;
}
.p-col-fixed{
  padding: 8px 8px 0px 8px !important;
  margin-bottom: 10px!important;
}
.dif{
  margin-left: 7px!important;
}

//=---------------------------search-----------------------------------

.div_button_add {
  display: none !important;
}
.p-column-filter{
  width: 100%;
}
.txt-width_135{
  max-width: 135px!important;
}

.p-dropdown-label{
  height: 45px!important;
}
.input_text{
  width: 372px!important;
  height: 45px!important;
  //padding: 12px!important;
  border: 1px solid #D0D4D9;
}
.Calendar{
  width: 372px!important;
  //height: 48px!important;
}
.div_company{
  margin: 0px 0px 0px 12px!important;
}
.div_date{
  //margin: 0px 0px 12px 0px!important;
}
.label_company{
  font-weight: bold!important;
  padding: 0px !important;
}
//-----

.p-inputtext{
  padding: 10px!important;
}
.div_search{
  display: flex!important;
  margin-bottom: 12px!important;
  //justify-content: space-between;
}

//------


.margin_botton{
  margin-bottom: 22px!important;
}
.p-button-success{
  background: #C40380!important;
}
div.p-dialog{
  width: 600px!important;
}
.div_pc{
  width: 372px!important;
}









//--------------------excel
/*.p-col-12 group-button-list-top group-button-list-top-custom{
  display: none!important;
}*/
/*.style_input_parcel{
  justify-content: space-between !important;
}*/
.left-parcel{
  float: left;
  width: calc(100% - 212px);
}
.right-parcel{
  width: 212px;
  float: right;
}

@media screen and (min-width: 1100px ) {
  .group-button-list-top-detail .p-button-success {
    background: #C40380 !important;
    height: 45px !important;
    margin: 35px 0px 0px 453px !important;
    border: 1px solid #C40380 !important;
  }
  // .p-column-title{
  //   display: none!important;
  // }
}

@media screen and (max-width: 600px) {
  .div_search{
    display: block!important;
  }
  .button{
    margin: 30px 0px 0px 0px!important;
    background: #C40380 !important;
    height: 45px !important;
    border: 1px solid #C40380 !important;
  }
  .button_import{
    display: flex!important;
    justify-content: flex-end!important;
  }
  .Calendar{
    width: 100%!important;
  }
  .div_pc{
    width: 100%!important;
  }
  .div_company{
    margin-left: 0px!important;
    margin-top: 10px!important;
  }
  .input_text{
    width: 100%!important;
  }
  .p-column-title{
    display: none!important;
  }
}


@media screen and (max-width: 1000px) {
  .div_search{
    display: block!important;
  }
  .button{
    margin: 30px 0px 0px 0px!important;
    background: #C40380 !important;
    height: 45px !important;
    border: 1px solid #C40380 !important;
  }
  .button_import{
    display: flex!important;
    justify-content: flex-end!important;
  }
  .Calendar{
    width: 100%!important;
  }
  .div_pc{
    width: 100%!important;
  }
  .div_company{
    margin-left: 0px!important;
    margin-top: 10px!important;
  }
  .input_text{
    width: 100%!important;
  }
  .p-column-title{
    display: none!important;
  }
}


</style>

<style lang="css">
/*.group-button-list-top-custom{
  display: none!important;
}*/

</style>